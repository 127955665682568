export const TIMINGS=[
    {
        name:'12am-1am',
        id:0
    },
    {
        name:'1am-2am',
        id:1
    },
    {
        name:'2am-3am',
        id:2
    },
    {
        name:'3am-4am',
        id:3
    },
    {
        name:'4am-5am',
        id:4
    },
    {
        name:'5am-6am',
        id:5
    },
    {
        name:'6am-7am',
        id:6
    },
    {
        name:'7am-8am',
        id:7
    },
    {
        name:'8am-9am',
        id:8
    },
    {
        name:'9am-10am',
        id:9
    },
    {
        name:'10am-11am',
        id:10
    },
    {
        name:'11am-12pm',
        id:11
    },
    {
        name:'12pm-1pm',
        id:12
    },
    {
        name:'1pm-2pm',
        id:13
    },
    {
        name:'2pm-3pm',
        id:14
    },
    {
        name:'3pm-4pm',
        id:15
    },
    {
        name:'4pm-5pm',
        id:16
    },
    {
        name:'5pm-6pm',
        id:17
    },
    {
        name:'6pm-7pm',
        id:18
    },
    {
        name:'7pm-8pm',
        id:19
    },
    {
        name:'8pm-9pm',
        id:20
    },
    {
        name:'9pm-10pm',
        id:21
    },
    {
        name:'10pm-11pm',
        id:22
    },
    {
        name:'11pm-12am',
        id:23
    },
  
]