import React from 'react'
import { ToastContainer} from 'react-toastify';
function Toast() {
    
  return (
    <ToastContainer
position="top-right"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
  )
}

export default Toast